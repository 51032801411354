.homepage {
    > div {
        @apply flex flex-col;
    }
    :global {
        .hero-banner-block {
            &.top-section {
                @apply order-first md:order-none;
            }
        }
        .cms-top-categories-block {
            @apply order-first md:order-none;
        }
    }
}